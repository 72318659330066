export const contentMaxWidth = `900px`

export const spacing = {
  small: "0.5rem",
  medium: "1rem",
  large: "2rem",
}

export const fontSize = {
  xSmall: "0.6rem",
  small: "0.8rem",
  medium: "1rem",
  large: "1.4rem",
  xLarge: "1.8rem",
}

export const colors = {
  copyLight: "#d3d3d3",
  copyMedium: "#9e9e9e",
  copyDark: "#0f0f0f",
  bgLight: "#f8f8f8",
  red: "#FA7580",
  blue: "#62A7FA",
  yellow: "#FCCF56",
  green: "#74c95a",
}
