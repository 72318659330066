import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImageProps } from "gatsby-image"
import ImageCompat from "./image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const HeaderImage: React.FC<GatsbyImageProps> = props => {
  const data = useStaticQuery(graphql`
    query {
      topImage: file(relativePath: { eq: "dodeca.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      middleImage: file(relativePath: { eq: "linked-hearts.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      bottomImage: file(relativePath: { eq: "stained-glass.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Fragment>
      <ImageCompat
        {...props}
        image={data.topImage}
        alt="mathematics-and-mythos-logo"
      />
      <ImageCompat
        {...props}
        image={data.middleImage}
        alt="mathematics-and-mythos-logo"
      />
      <ImageCompat
        {...props}
        image={data.bottomImage}
        alt="mathematics-and-mythos-logo"
      />
    </Fragment>
  )
}

export default HeaderImage
