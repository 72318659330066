export const config = {
  NODE_ENV: process.env.NODE_ENV,
  GATSBY_STRIPE_API_KEY: process.env.GATSBY_STRIPE_API_KEY,
}

export const allowedCountries = [
  "US",
  "AT",
  "AU",
  "BE",
  "BG",
  "CA",
  "CH",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GB",
  "GR",
  "HK",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "NO",
  "NZ",
  "PL",
  "PT",
  "RO",
  "SE",
  "SG",
  "SI",
  "SK",
]
