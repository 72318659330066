import React from "react"
import Img, { FluidObject } from "gatsby-image"

interface ChildImageSharp {
  fluid?: FluidObject | FluidObject[]
  fixed?: any
}

interface ImageCompatProps {
  alt: string
  childImageSharp?: ChildImageSharp
  image?:
    | {
        childImageSharp: ChildImageSharp
      }
    | string
}
const ImageCompat: React.FC<ImageCompatProps> = props => {
  const { alt = "", childImageSharp, image } = props

  if (childImageSharp) {
    return (
      <Img
        loading="eager"
        fluid={childImageSharp.fluid}
        fixed={childImageSharp.fixed}
        alt={alt}
      />
    )
  }

  if (typeof image === "string") {
    return <img src={image} alt={alt} />
  }

  if (image) {
    return <Img loading="eager" fluid={image.childImageSharp.fluid} alt={alt} />
  }

  return null
}

export default ImageCompat
