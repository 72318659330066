import { Link } from "gatsby"
import React, { HTMLProps } from "react"
import styled from "styled-components"
import useScrollPosition from "@react-hook/window-scroll"

import { fontSize, spacing, colors } from "../style/theme"

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const NavLink = styled(Link)`
  font-size: ${fontSize.large};
  text-decoration: none;
`
const SiteSubtitle = styled.span<{ pageScrolled: boolean }>`
  color: ${colors.copyMedium};
  text-decoration: none;
  margin-left: ${spacing.small};
  transition: opacity 0.4s ease-out;

  opacity: ${props => (props.pageScrolled ? 0 : 1)};
`

interface NavItemProps {
  name: string
  url: string
}
const NavItem: React.FC<NavItemProps> = props => {
  const { name, url } = props
  return (
    <NavLink
      to={url}
      activeStyle={{
        textDecoration: "underline",
      }}
    >
      {name}
    </NavLink>
  )
}

interface NavProps {
  siteTitle: string
  siteSubtitle: string
}
const Nav: React.FC<NavProps> = props => {
  const { siteTitle, siteSubtitle } = props
  const scrollY = useScrollPosition(30 /*fps*/)

  return (
    <StyledNav>
      <NavLink
        to={"/"}
        style={
          {
            // position: "absolute",
          }
        }
      >
        {siteTitle}
        <SiteSubtitle pageScrolled={scrollY > 150}>{siteSubtitle}</SiteSubtitle>
      </NavLink>
      {/* <NavLink to="/" style={{ visibility: "hidden" }}>
        {siteTitle}
      </NavLink> */}
      {[
        {
          name: "About",
          url: "/about",
        },
        {
          name: "Events",
          url: "/events",
        },
        {
          name: "Projects",
          url: "/projects",
        },
        {
          name: "Papers",
          url: "/papers",
        },
        // {
        //   name: "People",
        //   url: "/people",
        // },
        {
          name: "Contact",
          url: "/contact",
        },
      ].map(navItem => (
        <NavItem key={`nav__nav-item--${navItem.url}`} {...navItem} />
      ))}
    </StyledNav>
  )
}

Nav.propTypes = {}

Nav.defaultProps = {}

export default Nav
