/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./minireset.css"
import "./layout.css"
import "./base.css"
import Nav from "./nav"
import { contentMaxWidth, spacing, colors } from "../style/theme"
import HeaderImage from "./header-image"
import styled from "styled-components"
import { config } from "../lib/constants"

const NavWrapper = styled.div`
  position: fixed;
  padding: ${spacing.large};

  @media (max-width: 1150px) {
    position: relative;
    padding: ${spacing.medium};
  }
`
const LayoutContainer = styled.div`
  display: flex;
  flex: auto;
  color: ${colors.copyDark};
  flex-direction: column;

  @media (max-width: 1150px) {
    max-width: ${contentMaxWidth};
  }
`
const SideColumn = styled.div`
  min-width: 170px;
  width: 250px;
  @media (max-width: 1150px) {
    display: none;
  }
`
const ContentContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex: auto;
  padding: 0 ${spacing.large};
  max-width: ${contentMaxWidth};
  margin: 0 auto;
  margin-bottom: ${spacing.large};
  width: 70%;
  z-index: 1;

  @media (max-width: 1150px) {
    width: 100%;
    padding: 0 ${spacing.medium};
  }

  p {
    line-height: 1.5;
  }
`
const MainBottom = styled.div`
  display: flex;
  margin-top: 226px;

  @media (max-width: 1150px) {
    margin-top: 0;
    display: block;
  }
`
const SideImage = styled.div`
  position: fixed;
  width: 200px;
  right: 0;
  padding: 0 ${spacing.large};

  @media (max-width: 1150px) {
    display: none;
  }
`

const Layout: React.FC = props => {
  const { children } = props

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  useEffect(() => {
    if (config.NODE_ENV !== "production") {
      return
    }

    if (window.location.host !== "www.mathematicsandmythos.com") {
      window.location.replace(
        `https://www.mathematicsandmythos.com${window.location.pathname}`
      )
    }
  }, [])

  return (
    <LayoutContainer>
      <div>
        <NavWrapper>
          <Nav
            siteTitle={data.site.siteMetadata.title}
            siteSubtitle={data.site.siteMetadata.description}
          />
        </NavWrapper>
      </div>
      <MainBottom>
        <SideColumn />
        <ContentContainer>{children}</ContentContainer>
        <SideColumn>
          <SideImage>
            <HeaderImage />
          </SideImage>
        </SideColumn>
      </MainBottom>
    </LayoutContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
